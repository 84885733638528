import React from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
const ContentDetails = ({propContent, padding = "p-5"}) => {
  const {contentId} = useParams();
  const contents = useOutletContext();
  const {i18n} = useTranslation();
  const content = propContent ? propContent
  : contents?.find(cn=>cn.contentId.toString()===contentId);

  return (
    <div className={`home-card ${padding}`}>
      <h3>{i18n.language==="ar"?content?.arTitle:content?.enTitle}</h3>
      <hr className='mt-2'/>
      <div className='my-3' key={content?.id} dangerouslySetInnerHTML={{__html: i18n.language==="ar"?content?.arDescription:content?.enDescription}}></div>
    </div>
  )
}

export default ContentDetails