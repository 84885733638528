import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as utils from '../../utils/FormUtils/FileUploadUtils'
import { ValidationError } from 'yup';

const UploadFileForm = ({onSubmit, file}) => {
    const {t} = useTranslation();
    const [errors, setErrors] = useState();
    const taskInfo = t("taskInfo") 
    const handleUploadFileValidation= async(e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const formData = Object.fromEntries(data.entries());
        try {
        await utils.uploadFileValidationSchema(taskInfo)
        .validate(formData, {abortEarly: false});
        onSubmit(formData);
        setErrors(null)
        e.target.querySelectorAll('input').forEach(input => {
            input.value = '';
        });
      } catch (error) {
        if(error instanceof ValidationError){
          let allErrors = {};
          console.error("validation error...")
          error?.inner?.forEach((err)=>{
            allErrors[err.path]=err.message;
          })
          setErrors(allErrors)
        }
      }
    }
  return (
    <form className='w-full' onSubmit={handleUploadFileValidation} method='post'>
      <ul>
        <li className='text-danger'>يجب أن لا يتجاوز حجم الملف عن 50 ميقا</li>
      </ul>
        <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
            <div className="md:w-1/2">
                <label htmlFor="file" className="text-lg">{taskInfo.file?.label}</label>
                <input type="file" name="file" id="file" className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={taskInfo.file?.label} />
                {errors?.file&&<div className='text-red-600'>{errors?.file}</div>}
            </div>
            <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{taskInfo.uploadFileBtn}</button>
        </div>
    </form>
  )
}

export default UploadFileForm