import { useContext } from "react"
import AuthContext from "../provider/AuthProvider"
import ScreenContentContext from "../provider/ScreenContentProvider"

export const useAuth = () => {
    return useContext(AuthContext)
}

export const useScreenContent = () => {
    return useContext(ScreenContentContext)
}