import React, { useState } from 'react'
import {useAuth} from '../hooks/appHooks';
import useAxiosFetchApi from '../hooks/useFetch';
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import AppLoading from '../utils/AppLoading';
import * as utils from '../utils/FormUtils/FileUploadUtils'
import { useTranslation } from 'react-i18next';
import { MdDownload, MdUpload } from 'react-icons/md';
import { BsCheck2Circle } from 'react-icons/bs';
import { AiFillWarning } from "react-icons/ai";
import { IoCloseCircle } from 'react-icons/io5';
import { parseCustomDateTime } from '../utils/DateUtils';
import UploadFileModal from './modals/EditModal'
import UploadFileForm from '../pages/ExhibitorTasks/UploadFileForm';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate, BASE_URL } from '../apis/axios';
const SCREEN_CONTENTS_URL = "/contents/route";
const EXHIBITOR_UPLOAD_FILE_URL="/exhibitorTasks/save"

const Tasks = () => {
  const { auth, setAuth } = useAuth();
  const {t, i18n} = useTranslation();
  useCheckRouteAuthorization();
  const taskInfo = t("taskInfo") 
  const [uploadModal, setUploadModal] = useState({file: null, exhibitorId: null, isShown: false});
  const [contentState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/tasks"), {}, auth.token);
  const taskContents = contentState.data?.list;
  const files = auth.files;
  const tasks = auth.exhibitor?.tasks;
  const tasksLen = tasks?.filter(task=>!task.exhibitorFile.optional).length;
  const filesLen = files?.filter(file=>!file.optional).length;
  const completeProgress = Math.round((tasksLen/filesLen)*100);

  const handleUploadExhibitorFile = async (fd)=>{
    const requestBody ={exhibitorId: uploadModal.exhibitorId,
      exhibitorFileId: uploadModal.file.fileId}
      const formData = new FormData();
      utils.fillExhibitorFileData(formData, fd.file, requestBody);
    try {
      const fileUploadResponse = await axiosPrivate.post(EXHIBITOR_UPLOAD_FILE_URL,formData,
        {headers: { 'Accept-Language': i18n.language}}
      );
      setAuth({...auth, exhibitor: {...auth.exhibitor, tasks: [...tasks, fileUploadResponse.data?.data]}})
      toast.success(fileUploadResponse?.data.message);
      setUploadModal({exhibitorId: null, file: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    contentState.isLoading || !taskContents
      ? <AppLoading />
      : <div className='flex-col space-y-3'>
      {taskContents?.length>=1 &&
      <div dangerouslySetInnerHTML={{__html: i18n.language==="ar"?taskContents[0]?.arDescription:taskContents[0]?.enDescription}}></div>}
      <div>
        <ToastContainer/>
        <div>
          (المهام الإجبارية {tasksLen+"/"+filesLen} مكتملة)
          <div className="w-full bg-neutral-200 dark:bg-neutral-600">
            {}
            <div
            className="bg-primary-color text-center text-xs font-medium leading-none text-primary-100"
            style={{ width: completeProgress===0?0:completeProgress+"%" }}
            >
            {completeProgress}%
            </div>
          </div>
        </div>
        <div className="overflow-x-auto w-full scrollbar-custom">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        {i18n.language==="ar"
                        ? <th scope="col" className="whitespace-nowrap p-4 border-r">{taskInfo.arTitle.label.replace("*","")}</th>
                        : <th scope="col" className="whitespace-nowrap p-4 border-r">{taskInfo.enTitle.label.replace("*","")}</th>}
                        <th scope="col" className="whitespace-nowrap p-1 border-r">{taskInfo.dueDate.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-1 border-r">{taskInfo.sendDate}</th>
                        {/* <th scope="col" className="whitespace-nowrap p-1 border-r">{taskInfo.fileType.label.replace("*","")}</th> */}
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{taskInfo.guidesFile.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("statusTxt")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                      {!files || !tasks? <tr><td colSpan={7} className='p-10'>
                          <AppLoading/>
                          </td></tr>
                      : files.length===0?<tr><td colSpan={11} className='p-10'>{taskInfo.noFiles}</td></tr>
                      : files.map((file)=>{
                        const taskExist = tasks?.find(task=>task.exhibitorFile.fileId===file.fileId)
                        const taskApproved = taskExist?.status==="APPROVED";
                        const dueDate=parseCustomDateTime(file.fdueDate)
                        const dateExceed = new Date() >= dueDate;
                        const bgColor=dateExceed&&!taskExist?"bg-gray-100 text-red-600":"bg-white";
                      return <tr key={file.fileId} className={`${bgColor} border-b`}>
                      {i18n.language==="ar"
                      ? <td className="whitespace-nowrap border-r p-4">{file.arTitle} 
                        <span className='mx-2'>({file.optional ? "إختياري" : "إجباري"})</span>
                      </td>
                      : <td className="whitespace-nowrap border-r p-4">{file.enTitle} 
                        <span className='mx-2'>({file.optional ? "optional" : "mandatory"})</span>
                      </td>}
                      <td className="whitespace-nowrap border-r p-4">
                        {taskExist ? file.fdueDate : dateExceed?"تجاوز تاريخ التسليم" : file.fdueDate}
                      </td>
                      <td className="whitespace-nowrap border-r p-4">
                        {taskExist ? `أرسل ${taskExist.fsendDate}` : "_"}
                      </td>
                      {/* <td className="whitespace-nowrap border-r p-4">{file.fileType}</td> */}
                      <td className="whitespace-nowrap border-r p-4">
                        { taskExist ? "_"
                        : dateExceed ? "_"
                        : <div>
                          <MdDownload className='inline-block primary-color mx-1'/>
                          <a href={`${BASE_URL}/files/get/file/${file.guidesFile}`} target="_blank" rel="noopener noreferrer" className='normal-link'>{t("downloadTxt")}</a>
                          </div>
                        }
                      </td>
                      <td className="whitespace-nowrap border-r p-4">
                        { taskExist ? <p className='lowercase'>{taskExist?.status}
                            {taskApproved
                            ? <BsCheck2Circle color='green' className='inline-block mx-2'/>
                            : <AiFillWarning size={22} className='inline-block mx-2 text-warning-500'/>}
                          </p>
                        : <div className='text-red-500'>
                          <IoCloseCircle className='inline-block mx-1' size={22}/>
                          لم يتم الإرسال
                          </div>
                        }
                      </td>
                      <td className="whitespace-nowrap border-r py-4 flex justify-center">
                        { !taskExist && dateExceed ? "_"
                        : taskExist ? taskApproved
                        ? <a className='bg-green-300 mx-0.5' href={`${BASE_URL}/files/get/file/${taskExist?.file}`} target='_blank' rel="noreferrer">
                            <MdDownload size={17}/>
                        </a> 
                        : <a className='bg-warning-300 mx-0.5' href={`${BASE_URL}/files/get/file/${taskExist?.file}`} target='_blank' rel="noreferrer">
                              <MdDownload size={17}/>
                          </a> 
                        : <button className='bg-red-300 mx-0.5' onClick={()=> 
                            setUploadModal({exhibitorId:auth.exhibitor.exhibitorId, file, isShown: true})}>
                              <MdUpload size={17}/>
                          </button>}
                      </td>
                      <UploadFileModal title={taskInfo.uploadFileTitle}
                        showModal={uploadModal.isShown}
                        file={uploadModal.file}
                        setShowModal={(value)=>setUploadModal({exhibitorId:null, file:null, isShown: value})}>
                          <UploadFileForm onSubmit={handleUploadExhibitorFile}/>
                      </UploadFileModal>
                      </tr>
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
      {taskContents?.length>=2 &&
      <div dangerouslySetInnerHTML={{__html: i18n.language==="ar"?taskContents[1]?.arDescription:taskContents[1]?.enDescription}}></div>}
    </div>
  )
}

export default Tasks