import *as Yup from 'yup'

export const validationSchema =(visaTexts, isEditing, countriesList, requiredMessage)=> {
  const countries = countriesList.map(country=>country.name.common)
  return Yup.object({
    // Match a string that consists of 1 to 4 words, where each word is made up of one or more letters from any language, optionally followed by whitespace.
    fullName: Yup.string()
    .matches(/^(?:\p{L}+\s*){1,4}$/u, visaTexts.fullName.invalidFormat)
    .required(requiredMessage),
    nationality: Yup.string()
    .oneOf(countries, visaTexts?.nationality.notSupported)
    .required(requiredMessage),
    gender: Yup.string()
    .oneOf(["MALE", "FEMALE"], visaTexts?.gender.notSupported)
    .required(requiredMessage),
    age: Yup.number().typeError(visaTexts.age.onlyNumber).required(requiredMessage),
    placeOfBirth: Yup.string().required(requiredMessage),
    dateOfBirth: Yup.string().required(requiredMessage),
    // phone: Yup.string().matches(/^(009665|9665|05)\d{8}$/,visaTexts?.phone.phoneFormat).required(requiredMessage),
    passportType: Yup.string()
    .oneOf(["NORMAL", "OTHER"], visaTexts?.passportType.notSupported)
    .required(requiredMessage),
    passportNumber: Yup.string().required(requiredMessage),
    passportExpire: Yup.string().required(requiredMessage),
    passportIssuePlace: Yup.string().required(requiredMessage),
    passportPhoto: Yup.mixed().when([], {
    is: () => !isEditing,
    then: ()=> Yup.mixed()
      .test(
        'fileSize',
        'حجم الصورة غير مدعوم',
        value => value && value.size <= 1024 * 1024 * 25 // 25 MB
      )
      .test(
        'fileType',
        `الملفات المدعومة png, jpg, jpeg, pdf فقط`,
        value => value && ["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)
      ),
      otherwise: ()=> Yup.mixed().notRequired(), // No validation when editing
    }),
    companyName: Yup.string().required(requiredMessage),
    companyActivity: Yup.string().required(requiredMessage),
    jobTitle: Yup.string().required(requiredMessage),
    religion: Yup.string().required(requiredMessage),
    travelSource: Yup.string()
    .oneOf(countries, visaTexts?.travelSource.notSupported)
    .required(requiredMessage),

})}

export const fillExhibitorVisaData=(visaData, passportPhoto, requestBody)=>{
    visaData.append("VisaPayload", JSON.stringify({
        visaId: requestBody.visaId, exhibitorId: requestBody.exhibitorId,
        placeOfBirth: requestBody.placeOfBirth,
        dateOfBirth: requestBody.dateOfBirth,
        fullName: requestBody.fullName,
        age: parseInt(requestBody.age),
        gender: requestBody.gender,
        nationality: requestBody.nationality,
        passportType: requestBody.passportType,
        passportNumber: requestBody.passportNumber,
        passportExpire: requestBody.passportExpire,
        passportIssuePlace: requestBody.passportIssuePlace,
        companyName: requestBody.companyName,
        jobTitle: requestBody.jobTitle,
        companyActivity: requestBody.companyActivity,
        religion: requestBody.religion,
        travelSource: requestBody.travelSource,

      }));
      visaData.append("PassportPhoto", passportPhoto);
}