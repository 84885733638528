import React, { useEffect, useState } from 'react'
import useCheckRouteAuthorization from '../../apis/useCheckRouteAuthorization';
import * as utils from '../../utils/FormUtils/AgreementUtils'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { axiosPrivate } from '../../apis/axios';
import { useAuth } from '../../hooks/appHooks';
import useAxiosFetchApi from '../../hooks/useFetch';
import ContentDetails from '../ContentDetails';
import AppLoading from '../../utils/AppLoading';

const SCREEN_CONTENTS_URL = "/contents/route";
const AGREEMENT_SAVE_INFO_URL = "/agreements/save"

const AddOrEditAgreements = () => {
  useCheckRouteAuthorization();
  const { auth, setAuth } = useAuth();
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const agreementInfo = t("agreementInfo");
  const [contentState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/exh-agreements"), {}, auth.token);
  const agreementContents = contentState.data?.list;
  const agreement = location.state?.agreement;
  const route = location.pathname.split("/")[2];
  useEffect(()=>{
    if((!agreement&&route==="edit")) navigate("/exh-agreements")
  }, [navigate, agreement, route])

  const [errors, setErrors] = useState();
  
    const handleAddOrEditAgreement = async(e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const formData = Object.fromEntries(data.entries());
        try {
        await utils.validationSchema(agreementInfo, formData, t("requiredMessage"))
        .validate(formData, {abortEarly: false});
        const requestBody = {
          ...formData,
          agreementId: agreement?agreement?.agreementId:null,
          exhibitorId: auth.exhibitor.exhibitorId
        }
        const agreementData = new FormData();
        utils.fillExhibitorAgreementData(agreementData, formData.signatoryLogo, formData.cosignerLogo ,requestBody)
        const infoResponse = await axiosPrivate.post(AGREEMENT_SAVE_INFO_URL, agreementData,
            {headers: {'Accept-Language': i18n.language}}
        );
        var agreementList = auth.exhibitor.agreements;
        if(!agreement){ 
          e.target.querySelectorAll('input').forEach(input => {
            input.value = '';
          });
        } else {agreementList = auth.exhibitor.agreements.filter(con=>con.agreementId!==agreement?.agreementId)}
        setAuth({...auth, exhibitor: {...auth.exhibitor, agreements: [...agreementList, infoResponse.data?.data]}})
        toast.success(infoResponse?.data.message);
      } catch (error) {
        if(error instanceof ValidationError){
          let allErrors = {};
          console.error("validation error...")
          error?.inner?.forEach((err)=>{
            allErrors[err.path]=err.message;
          })
          setErrors(allErrors)
        }
        toast.error(error.response?.data.message)
      }
    }

  return (
    <div>
      <ToastContainer/>
        <h3>{agreement?agreementInfo.editAgreementTxt:agreementInfo.addAgreementTxt}</h3>
        <div className='my-6'>
          {contentState.isLoading
          ? <AppLoading />
          : agreementContents?.map(content=> <ContentDetails padding={"p-1 ps-3"} key={content.contentId} propContent={content}/>)}
        </div>
        <div className='flex justify-between items-center'>
            <form className='w-full' onSubmit={handleAddOrEditAgreement} method='post'>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="signatory" className="text-lg">{agreementInfo.signatory?.label}</label>
                      <input type="text" name="signatory" id="signatory" defaultValue={agreement?.signatory} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={agreementInfo.signatory?.label} />
                      {errors?.signatory&&<div className='text-red-600'>{errors?.signatory}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="cosigner" className="text-lg">{agreementInfo.cosigner?.label}</label>
                      <input type="text" name="cosigner" id="cosigner" defaultValue={agreement?.cosigner} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={agreementInfo.cosigner?.label} />
                      {errors?.cosigner&&<div className='text-red-600'>{errors?.cosigner}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="title" className="text-lg">{agreementInfo.title?.label}</label>
                      <input type="text" name="title" id="title" defaultValue={agreement?.title} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={agreementInfo.title?.label} />
                      {errors?.title&&<div className='text-red-600'>{errors?.title}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="signatoryLogo" className="text-lg">{agreementInfo.signatoryLogo?.label}</label>
                      <input type="file" name="signatoryLogo" id="signatoryLogo" className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={agreementInfo.signatoryLogo?.label} />
                      {errors?.signatoryLogo&&<div className='text-red-600'>{errors?.signatoryLogo}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="cosignerLogo" className="text-lg">{agreementInfo.cosignerLogo?.label}</label>
                      <input type="file" name="cosignerLogo" id="cosignerLogo" className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={agreementInfo.cosignerLogo?.label} />
                      {errors?.cosignerLogo&&<div className='text-red-600'>{errors?.cosignerLogo}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="cost" className="text-lg">{agreementInfo.cost?.label}</label>
                      <input type="number" name="cost" id="cost" defaultValue={agreement?.cost} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={agreementInfo.cost?.label} />
                      {errors?.cost&&<div className='text-red-600'>{errors?.cost}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="notes" className="text-lg">{agreementInfo.notes?.label}</label>
                      <textarea type="text" name="notes" id="notes" defaultValue={agreement?.notes} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5 scrollbar-custom" placeholder={agreementInfo.notes?.label} />
                      {errors?.notes&&<div className='text-red-600'>{errors?.notes}</div>}
                    </div>
                    <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{route==="edit"?t("editBtn"):t("addBtn")}</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddOrEditAgreements;