import *as Yup from 'yup'

export const validationSchema =(badgeTexts, formData, requiredMessage)=> {return Yup.object({
    firstName: Yup.string().required(requiredMessage),
    lastName: Yup.string().required(requiredMessage),
    companyName: Yup.string().required(requiredMessage),
    jobTitle: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    phone: Yup.string().when([], {
    is: () => formData.phone,
    then: ()=> Yup.string().matches(/^(009665|9665|05)\d{8}$/,badgeTexts?.phone.phoneFormat).required(requiredMessage),
    otherwise: ()=> Yup.string().notRequired(),
    }),
    email: Yup.string().when([], {
    is: () => formData.email,
    then: ()=> Yup.string().email(badgeTexts?.email.emailFormat).required(requiredMessage),
    otherwise: ()=> Yup.string().notRequired(),
    }),
    type: Yup.string().when([], {
    is: () => formData.type,
    then: ()=> Yup.string()
    .oneOf(["VIP", "MEDIA", "EXHIBITOR", "SPONSOR"], badgeTexts?.type.invalidType)
    .required(requiredMessage),
    otherwise: ()=> Yup.string().notRequired(),
    }),
})}