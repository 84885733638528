import *as Yup from 'yup'

export const uploadFileValidationSchema =(fileTexts)=> {
//   const supportedExtension = getMimeType(extension);
  return Yup.mixed({
    file: Yup.mixed()
    .test(
      'fileSize',
      'حجم الصورة غير مدعوم',
      value => value && value.size <= 1024 * 1024 * 50// 50 MB
    )
    // .test(
    //   'fileType',
    //   `${fileTexts.onlySupport} pdf, psd, ai and dwg`,
    //   value => value && ["application/pdf"].includes(value.type)
    // ),
})}
// function getMimeType(extension) {
//     if (extension.includes("pdf")) {
//         return "application/pdf";
//     } else if (extension.includes("doc") || extension.includes("docx")) {
//         return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
//     } else if (extension.includes("xls") || extension.includes("xlsx")) {
//         return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
//     } else if (extension.includes("txt")) {
//         return "text/plain";
//     } else {
//         return "unknown";
//     }
// }

export const fillExhibitorFileData=(exhibitorFileData, file, requestBody)=>{
    exhibitorFileData.append("ExhibitorTaskPayload", JSON.stringify({
        exhibitorFileId: requestBody.exhibitorFileId, exhibitorId: requestBody.exhibitorId
      }));
      exhibitorFileData.append("TaskFile", file);
}