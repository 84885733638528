import React, { useState } from 'react'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useAuth } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from './modals/ConfirmationModal';
import { BsCheck2Circle } from 'react-icons/bs';
import { MdDelete, MdEdit, MdDownload } from 'react-icons/md';
import { Link } from 'react-router-dom';
import AppLoading from '../utils/AppLoading';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate, BASE_URL } from '../apis/axios';
const VISA_DELETE_URL = "/visas/delete";

const Visa = () => {
  const { auth, setAuth } = useAuth();
  const {t, i18n} = useTranslation();
  useCheckRouteAuthorization();
  const visaInfo = t("visaInfo") 
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState({visaId: null, isShown: false});
  const allVisas = auth.exhibitor?.visas;

  const handleDeleteVisa = async ()=>{
    try {
      const visaDeletedResponse = await axiosPrivate.delete(VISA_DELETE_URL+`/${deleteModal.visaId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(visaDeletedResponse?.data.message);
      const afterDeleted = allVisas.filter(visa=> visa.visaId !== deleteModal.visaId);
      setAuth({...auth, exhibitor: {...auth.exhibitor, visas: afterDeleted}})
      setDeleteModal({visa: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  const visas = allVisas?.filter((data) =>
    Object.values(data).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div>
      <div>
        <ToastContainer/>
        <div className="flex justify-between w-full mt-12">
          <div className='flex w-1/2'>
              <h2>{visaInfo.visasTitle}</h2>
              <input value={searchQuery}
                     onChange={(e) => setSearchQuery(e.target.value)}
                     className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={visaInfo.searchVisaTxt} />
          </div>
          <Link className='bg-primary-color text-white p-2 text-sm'
          to={"add"}>
              {visaInfo.addVisaTxt}
          </Link>
        </div>
        <div className="overflow-x-auto w-full mb-12 scrollbar-custom">
          <div className="inline-block min-w-full my-10">
              <div className="overflow-hidden">
                  <table className="min-w-full border text-center text-sm">
                  <thead
                      className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                      <tr key={"head-1"}>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.fullName.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.nationality.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-1 border-r">{visaInfo.gender.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-1 border-r">{visaInfo.age.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-1 border-r">{visaInfo.placeOfBirth.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.dateOfBirth.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportType.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportNumber.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportExpire.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportIssuePlace.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportPhoto.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.companyName.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.companyActivity.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.jobTitle.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.religion.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.travelSource.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{t("statusTxt")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                      </tr>
                  </thead>
                  <tbody className='border-b'>
                    {!allVisas? <tr><td colSpan={18} className='p-10'>
                        <AppLoading/>
                        </td></tr>
                    : visas.length===0?<tr><td colSpan={18} className='p-10'>{visaInfo.noVisas}</td></tr>
                    : visas.map((visa)=>{
                      const visaApproved = visa?.status==="APPROVED";
                    return <tr key={visa.visaId} className='border-b'>
                    <td className="whitespace-nowrap border-r p-4">{visa.fullName} </td>
                    <td className="whitespace-nowrap border-r p-4">{visa.nationality} </td>
                    <td className="whitespace-nowrap border-r p-4">{visa.gender}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.age}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.placeOfBirth}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.dateOfBirth}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.passportType}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.passportNumber}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.passportExpire}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.passportIssuePlace}</td>
                    <td className="whitespace-nowrap border-r p-4">
                      <div>
                        <MdDownload className='inline-block primary-color mx-1'/>
                        <a href={`${BASE_URL}/files/get/file/${visa.passportPhoto}`} target="_blank" rel="noopener noreferrer" className='normal-link'>{t("downloadTxt")}</a>
                      </div>
                    </td>
                    <td className="whitespace-nowrap border-r p-4">{visa.companyName}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.companyActivity}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.jobTitle}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.religion}</td>
                    <td className="whitespace-nowrap border-r p-4">{visa.travelSource}</td>
                    <td className="whitespace-nowrap border-r p-4">
                      {<p className='lowercase'>{visa.status}</p>}
                    </td>
                    <td className="whitespace-nowrap border-r py-4 flex justify-center">
                      { visaApproved?<BsCheck2Circle color='green'/>
                      : <>
                          <Link className='bg-green-300 mx-0.5' to={"edit"} state={{visa}}>
                              <MdEdit size={17}/>
                          </Link>
                          <button className='bg-red-300 mx-0.5' onClick={()=> 
                            setDeleteModal({visaId: visa.visaId, isShown: true})}>
                              <MdDelete size={17}/>
                          </button>
                        </>}
                    </td>
                    <ConfirmationModal
                      key={visa?.visaId}
                      title={visaInfo.confirmDeleteTitle}
                      btnColor={"bg-danger"}
                      message={visaInfo.confirmDeleteBody}
                      onAction={handleDeleteVisa}
                      showModal={deleteModal.isShown}
                      setShowModal={(value)=>setDeleteModal({visaId: null, isShown: value})} />
                    </tr>
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Visa