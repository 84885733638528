import React, { useEffect } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/appHooks';
import useAxiosFetchApi from '../hooks/useFetch';
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useTranslation } from 'react-i18next';
import AppLoading from '../utils/AppLoading';
const SCREEN_CONTENTS_URL = "/contents/route";
const GeneralInfo = () => {
  useCheckRouteAuthorization();
  
  const { auth } = useAuth();
  const {i18n} = useTranslation();
  const [contentState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/general-info"), {}, auth.token);
  const generalInfoScreenContents = contentState.data?.list;
  const location = useLocation();
  const route = location.pathname.split("/")[2];
  const navigate = useNavigate();

  useEffect(()=>{
    if(generalInfoScreenContents && !route) navigate("/general-info/"+generalInfoScreenContents?.[0].contentId)
  }, [navigate, generalInfoScreenContents, route])
  
  return (
    <div className="flex justify-between items-start">
      
      <div className='flex flex-col justify-between text-white bg-primary-color p-7 min-w-[25%] w-[25%]'>
        {contentState.isLoading
        ? <AppLoading />
        : !generalInfoScreenContents
        ? <p>{contentState.data.message??contentState.error.message}</p>
        : generalInfoScreenContents.map(content => (
          <NavLink className='hover:bg-white p-2 border-none shadow-none' to={content.contentId.toString()} key={content.contentId}>
            {i18n.language==="ar"?content.arTitle:content.enTitle}
          </NavLink>
          ))}
      </div>
      <div className='flex flex-1 flex-col mx-5 justify-between'>
          <Outlet context={generalInfoScreenContents}/>
      </div>
    </div>
  )
}

export default GeneralInfo;