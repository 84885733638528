import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export default axios.create({
    baseURL: BASE_URL,
    headers: { "Access-Control-Allow-Origin": '*' }
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL
});