import *as Yup from 'yup'
export const servicesList = [
  "بناء الجناح",
  "الضيافة",
  "الدعاية والخدمات",
  "خدمات النظافة"
]
export const validationSchema =(contractorTexts, formData, requiredMessage)=> {
  
  return Yup.object({
  
    personName: Yup.string().matches(/^(?:\p{L}+\s*){1,4}$/u, contractorTexts.personName.invalidFormat)
    .required(requiredMessage),
    crNumber: Yup.string().notRequired(),
    companyName: Yup.string().required(requiredMessage),
    companyAddress: Yup.string().notRequired(),
    companyPhone: Yup.string().matches(/^(009665|9665|05)\d{8}$/,contractorTexts?.companyPhone.phoneFormat)
    .required(requiredMessage),
    personEmail: Yup.string().email(contractorTexts?.personEmail.emailFormat).notRequired(),
    personPhone: Yup.string().matches(/^(009665|9665|05)\d{8}$/,contractorTexts?.personPhone.phoneFormat)
    .required(requiredMessage),
    service: Yup.string().when([], {
      is: () => formData.service,
      then: ()=> Yup.string().oneOf(servicesList, contractorTexts?.service.notSupported),
      otherwise: ()=> Yup.string().notRequired()
    }),
    crImgCopy: Yup.mixed().when([], {
    is: () => formData.crImgCopy.name!=='',
    then: ()=> Yup.mixed()
      .test(
        'fileSize',
        'حجم الصورة غير مدعوم',
        value => value && value.size <= 1024 * 1024 * 25 // 25 MB
      )
      .test(
        'fileType',
        `الملفات المدعومة png, jpg, jpeg, pdf فقط`,
        value => value && ["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(value.type)
      ).notRequired(),
      otherwise: ()=> Yup.mixed().notRequired(), // No validation when editing
    }),
})}

export const fillExhibitorContractorData=(contractorData, crImgCopy, requestBody)=>{
    contractorData.append("ContractorPayload", JSON.stringify({
        contractorId: requestBody.contractorId, exhibitorId: requestBody.exhibitorId,
        personName: requestBody.personName,
        crNumber: requestBody.crNumber,
        companyName: requestBody.companyName,
        companyAddress: requestBody.companyAddress,
        companyPhone: requestBody.companyPhone,
        personEmail: requestBody.personEmail,
        personPhone: requestBody.personPhone,
        service: requestBody.service
      }));
      contractorData.append("crImgCopy", crImgCopy);
}