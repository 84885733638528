import React from 'react'

const AppLoading = () => {
  return (
    <div className='flex justify-center items-center h-[100vh]'>
      <p className='stroke-zinc-800 text-4xl'>Now Loading...</p>
    </div>
  )
}

export default AppLoading