import React from 'react'
import {MdDoorSliding} from 'react-icons/md'
import { GiResize } from "react-icons/gi";
import { AiFillWarning } from "react-icons/ai";
import { BsBoxArrowLeft, BsBoxArrowRight } from "react-icons/bs";
import { IoCloudDoneSharp } from "react-icons/io5";
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization'
import { useAuth } from '../hooks/appHooks';
import AppLoading from '../utils/AppLoading';
import useAxiosFetchApi from '../hooks/useFetch';
import ContentDetails from './ContentDetails';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const SCREEN_CONTENTS_URL = "/contents/route";

const Homepage = () => {
  useCheckRouteAuthorization();
  const{t, i18n} = useTranslation();
  const { auth } = useAuth();
  const [homeState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/home"), {}, auth.token);
  const homeContents = homeState.data?.list;
  const exhibitorFiles = auth.files;
  const exhibitor = auth.exhibitor;

  return (
    !exhibitor?<AppLoading />: // check if exhibitor undefined
  <div className='flex gap-10 items-start'>
    <div className='flex flex-col gap-6 w-full lg:w-9/12'>
      {homeState.isLoading
        ? <AppLoading />
        : !homeContents
        ? <p>{homeState.data.message??homeState.error.message}</p>
        : homeContents.map(homeContent => (
          <ContentDetails key={homeContent.contentId} propContent={homeContent}/>
        ))}
    </div>
    <div className="flex flex-col space-y-14 w-full lg:w-4/12">
      <div className='home-card p-5 bg-white'>
        <div className='flex justify-between'>
          <p>{t("taskSummary")}</p>
          <Link to={"/tasks"} className='bg-slate-800 text-white text-sm p-0 border-none hover:bg-slate-800 hover:text-white hover:border-none'>
            <span className='p-1'>{t("taskPage")}</span>
            {i18n.language==="ar"
            ? <BsBoxArrowLeft size={27} className='p-1 bg-slate-500 inline-block ms-2' />
            : <BsBoxArrowRight size={27} className='p-1 bg-slate-500 inline-block ms-2' />}
          </Link>
        </div>
        <div className='flex justify-between mt-5'>
          <mark className='bg-slate-800 text-white p-1 text-sm'>{exhibitor.user.username}</mark>
          <p className='border border-primary-color primary-color px-1 uppercase text-sm'>{exhibitor.exhibitorType}</p>
          <p className='border border-primary-color primary-color px-1 uppercase text-sm'>{exhibitor.fileType}</p>
        </div>
        <h1 className='font-semibold mt-2 text-lg'>{`${exhibitor.firstName} ${exhibitor.lastName}`}</h1>
        <h3 className='mt-2 text-sm'>{exhibitor.company} Company</h3>
        <div className='flex space-x-3 space-y-2'>
          <MdDoorSliding size={24} className='text-slate-800 m-2' />
          <p>{`رقم الجناح ${exhibitor.standNumber}`}</p>
        </div>
        <div className='flex justify-between items-start'>
          <div className='flex space-x-2 space-y-2'>
            <GiResize size={20} className='text-slate-800 m-2' />
            <p>{`مساحة الجناح ${exhibitor.standSize}`}</p>
          </div>
        </div>
      </div>
      {!exhibitorFiles?<AppLoading/>// check if exhibitor files undefined
        :exhibitorFiles.map(file=>{
          const task = exhibitor.tasks.find(task=>task.exhibitorFile.fileId===file.fileId)
          const statusCompleted = task?.status==="APPROVED";
        return <div key={file.fileId} className={`home-card p-3 ${statusCompleted?"bg-success-100":"bg-danger-100"}`}>
          <div className='flex'>
            <div className='flex flex-col items-center text-sm'>
              {statusCompleted?
              <IoCloudDoneSharp size={34} className='text-green-600 mt-2' />
              :<AiFillWarning size={34} className='text-danger-600 mt-2' />
              }
              <p>{statusCompleted
              ? i18n.language==="ar"?"مكتمل":"Completed"
              : i18n.language==="ar"?"قيد الإنتظار":"Pending"}</p>
            </div>
            <div className="flex flex-col flex-1 ms-3">
              <h4>{i18n.language==="ar"?file.arTitle:file.enTitle}</h4>
              <p className='text-sm'>( {file.optional
              ? i18n.language==="ar"?"إختياري":"Optional"
              : i18n.language==="ar"?"إجباري":"Mandatory"} )</p>
            </div>
          </div>
        </div>})
      }
    </div>
  </div>
  )
}

export default Homepage