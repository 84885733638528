
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import './App.css';
import RequiredAuth from './layouts/RequiredAuth';
import Homepage from './pages/Homepage';
import Tasks from './pages/Tasks';
import RulesReg from './pages/RulesReg';
import ServiceUtil from './pages/ServiceUtil';
import Visa from './pages/Visa';
import Badges from './pages/Badges';
import FloorPlan from './pages/FloorPlan';
import Contractors from './pages/Contractors';
import { AuthProvider } from './provider/AuthProvider';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Unauthorized from './pages/Unauthorized';
import ContentDetails from './pages/ContentDetails';
import GeneralInfo from './pages/GeneralInfo';
import AddOrEditBadges from './pages/Badges/AddOrEditBadges';
import AddOrEditVisas from './pages/Visas/AddOrEditVisas';
import AddOrEditContractors from './pages/Contractors/AddOrEditContractors';
import Agreements from './pages/Agreements';
import AddOrEditAgreements from './pages/Agreements/AddOrEditAgreements';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path='*' element={<NotFound />}/>
      <Route path="/" element={<RequiredAuth />}>
        <Route path='home' element={<Homepage />}/>
        <Route path='general-info' element={<GeneralInfo />}>
          <Route path=':contentId' element={<ContentDetails/>}/>
        </Route>
        <Route path='tasks' element={<Tasks />}/>
        <Route path='rules-reg' element={<RulesReg />}>
          <Route path=':contentId' element={<ContentDetails/>}/>
        </Route>
        <Route path='service-util' element={<ServiceUtil />}>
          <Route path=':contentId' element={<ContentDetails/>}/>
        </Route>
        <Route path='floor-plan' element={<FloorPlan />}/>
        <Route path='contractors' element={<Contractors />}/>
        <Route path='contractors/add' element={<AddOrEditContractors />}/>
        <Route path='contractors/edit' element={<AddOrEditContractors />}/>
        <Route path='exh-agreements' element={<Agreements />}/>
        <Route path='exh-agreements/add' element={<AddOrEditAgreements />}/>
        <Route path='exh-agreements/edit' element={<AddOrEditAgreements />}/>
        <Route path='exh-visas' element={<Visa />}/>
        <Route path='exh-visas/add' element={<AddOrEditVisas />}/>
        <Route path='exh-visas/edit' element={<AddOrEditVisas />}/>
        <Route path='badges' element={<Badges />}/>
        <Route path='badges/add' element={<AddOrEditBadges />}/>
        <Route path='badges/edit' element={<AddOrEditBadges />}/>
      </Route>
    </Route>
  )
)

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router}/>
    </AuthProvider>
  );
}

export default App;
