import { useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

const useCheckRouteAuthorization = () => {

    const screens = useOutletContext();
    const location = useLocation();
    const navigate = useNavigate();
    const routePath = location.pathname.split("/")[1]
    const allowedRoute = screens.some(screen => screen.route === routePath);
    useEffect(() => {
        let didCancel=false;
        if(!allowedRoute && !didCancel){
        navigate("/unauthorized")
        }
        return () => {
        didCancel=true;
        }
    }, [allowedRoute, navigate])

    return screens;
}

export default useCheckRouteAuthorization