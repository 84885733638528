import React, { useState } from 'react'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useAuth } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from './modals/ConfirmationModal';
import { BsCheck2Circle } from 'react-icons/bs';
import { MdDelete, MdEdit, MdDownload } from 'react-icons/md';
import { Link } from 'react-router-dom';
import AppLoading from '../utils/AppLoading';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate, BASE_URL } from '../apis/axios';
const CONTRACTOR_DELETE_URL = "/contractors/delete";

const Contractors = () => {
  const { auth, setAuth } = useAuth();
  const {t, i18n} = useTranslation();
  useCheckRouteAuthorization();
  const contractorInfo = t("contractorInfo")
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState({contractorId: null, isShown: false});
  // const [contentState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/contractors"), {}, auth.token);
  // const contractorContents = contentState.data?.list;
  const allContractors = auth.exhibitor?.contractors;

  const handleDeleteContractor = async ()=>{
    try {
      const contractorDeletedResponse = await axiosPrivate.delete(CONTRACTOR_DELETE_URL+`/${deleteModal.contractorId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(contractorDeletedResponse?.data.message);
      const afterDeleted = allContractors.filter(contractor=> contractor.contractorId !== deleteModal.contractorId);
      setAuth({...auth, exhibitor: {...auth.exhibitor, contractors: afterDeleted}})
      setDeleteModal({contractor: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  const contractors = allContractors?.filter((data) =>
    Object.values(data).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div>
      <div>
        <ToastContainer/>
        <div className="flex justify-between w-full mt-12">
          <div className='flex w-1/2'>
              <h2>{contractorInfo.contractorsTitle}</h2>
              <input value={searchQuery}
                     onChange={(e) => setSearchQuery(e.target.value)}
                     className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={contractorInfo.searchContractorTxt} />
          </div>
          <Link className='bg-primary-color text-white p-2 text-sm'
          to={"add"}>
              {contractorInfo.addContractorTxt}
          </Link>
        </div>
        <div className="overflow-x-auto w-full mb-12 scrollbar-custom">
          <div className="inline-block min-w-full my-10">
              <div className="overflow-hidden">
                  <table className="min-w-full border text-center text-sm">
                  <thead
                      className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                      <tr key={"head-1"}>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.companyName.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-1 border-r">{contractorInfo.companyPhone.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-1 border-r">{contractorInfo.personName.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.personPhone.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.crNumber.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-1 border-r">{contractorInfo.companyAddress.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.personEmail.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.crImgCopy.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.service.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{t("statusTxt")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                      </tr>
                  </thead>
                  <tbody className='border-b'>
                    {!allContractors? <tr><td colSpan={11} className='p-10'>
                        <AppLoading/>
                        </td></tr>
                    : contractors.length===0?<tr><td colSpan={11} className='p-10'>{contractorInfo.noContractors}</td></tr>
                    : contractors.map((contractor)=>{
                      const contractorApproved = contractor?.status==="APPROVED";
                    return <tr key={contractor.contractorId} className='border-b'>
                    <td className="whitespace-nowrap border-r p-4">{contractor.companyName} </td>
                    <td className="whitespace-nowrap border-r p-4">{contractor.companyPhone}</td>
                    <td className="whitespace-nowrap border-r p-4">{contractor.personName}</td>
                    <td className="whitespace-nowrap border-r p-4">{contractor.personPhone}</td>
                    <td className="whitespace-nowrap border-r p-4">{contractor.crNumber??'_'} </td>
                    <td className="whitespace-nowrap border-r p-4">{contractor.companyAddress??'_'}</td>
                    <td className="whitespace-nowrap border-r p-4">{contractor.personEmail??'_'}</td>
                    <td className="whitespace-nowrap border-r p-4">
                      <div>{contractor.crImgCopy?
                        <><MdDownload className='inline-block primary-color mx-1'/>
                        <a href={`${BASE_URL}/files/get/file/${contractor.crImgCopy}`} target="_blank" rel="noopener noreferrer" className='normal-link'>{t("downloadTxt")}</a></>
                        : '_'
                        }
                      </div>
                    </td>
                    <td className="whitespace-nowrap border-r p-4">{contractor.service??'_'}</td>
                    <td className="whitespace-nowrap border-r p-4">
                      {<p className='lowercase'>{contractor.status}</p>}
                    </td>
                    <td className="whitespace-nowrap border-r py-4 flex justify-center">
                      { contractorApproved?<BsCheck2Circle color='green'/>
                      : <>
                          <Link className='bg-green-300 mx-0.5' to={"edit"} state={{contractor}}>
                              <MdEdit size={17}/>
                          </Link>
                          <button className='bg-red-300 mx-0.5' onClick={()=> 
                            setDeleteModal({contractorId: contractor.contractorId, isShown: true})}>
                              <MdDelete size={17}/>
                          </button>
                        </>}
                    </td>
                    <ConfirmationModal
                      key={contractor?.contractorId}
                      title={contractorInfo.confirmDeleteTitle}
                      btnColor={"bg-danger"}
                      message={contractorInfo.confirmDeleteBody}
                      onAction={handleDeleteContractor}
                      showModal={deleteModal.isShown}
                      setShowModal={(value)=>setDeleteModal({contractorId: null, isShown: value})} />
                    </tr>
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contractors