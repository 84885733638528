import React, { useEffect, useState } from 'react'
import useCheckRouteAuthorization from '../../apis/useCheckRouteAuthorization';
import * as utils from '../../utils/FormUtils/ContractorUtils'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { axiosPrivate } from '../../apis/axios';
import { useAuth } from '../../hooks/appHooks';
import useAxiosFetchApi from '../../hooks/useFetch';
import ContentDetails from '../ContentDetails';
import AppLoading from '../../utils/AppLoading';

const SCREEN_CONTENTS_URL = "/contents/route";
const CONTRACTOR_SAVE_INFO_URL = "/contractors/save"

const AddOrEditContractors = () => {
  useCheckRouteAuthorization();
  const { auth, setAuth } = useAuth();
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const contractorInfo = t("contractorInfo");
  const [contentState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/contractors"), {}, auth.token);
  const contractorContents = contentState.data?.list;
  const contractor = location.state?.contractor;
  const route = location.pathname.split("/")[2];
  useEffect(()=>{
    if((!contractor&&route==="edit")) navigate("/contractors")
  }, [navigate, contractor, route])

  const [errors, setErrors] = useState();
  
    const handleAddOrEditContractor = async(e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const formData = Object.fromEntries(data.entries());
        try {
        await utils.validationSchema(contractorInfo, formData ,t("requiredMessage"))
        .validate(formData, {abortEarly: false});
        const requestBody = {
          ...formData,
          contractorId: contractor?contractor?.contractorId:null,
          exhibitorId: auth.exhibitor.exhibitorId
        }
        const contractorData = new FormData();
        utils.fillExhibitorContractorData(contractorData, formData.crImgCopy ,requestBody)
        const infoResponse = await axiosPrivate.post(CONTRACTOR_SAVE_INFO_URL, contractorData,
            {headers: {'Accept-Language': i18n.language}}
        );
        var contractorList = auth.exhibitor.contractors;
        if(!contractor){ 
          e.target.querySelectorAll('input').forEach(input => {
            input.value = '';
          });
        } else {contractorList = auth.exhibitor.contractors.filter(con=>con.contractorId!==contractor?.contractorId)}
        setAuth({...auth, exhibitor: {...auth.exhibitor, contractors: [...contractorList, infoResponse.data?.data]}})
        toast.success(infoResponse?.data.message);
      } catch (error) {
        if(error instanceof ValidationError){
          let allErrors = {};
          console.error("validation error...")
          error?.inner?.forEach((err)=>{
            allErrors[err.path]=err.message;
          })
          setErrors(allErrors)
        }
        toast.error(error.response?.data.message)
      }
    }

  return (
    <div>
      <ToastContainer/>
        <h3>{contractor?contractorInfo.editContractorTxt:contractorInfo.addContractorTxt}</h3>
        <div className='my-6'>
          {contentState.isLoading
          ? <AppLoading />
          : contractorContents?.map(content=> <ContentDetails padding={"p-1 ps-3"} key={content.contentId} propContent={content}/>)}
        </div>
        <div className='flex justify-between items-center'>
            <form className='w-full' onSubmit={handleAddOrEditContractor} method='post'>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="companyName" className="text-lg">{contractorInfo.companyName?.label}</label>
                      <input type="text" name="companyName" id="companyName" defaultValue={contractor?.companyName} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={contractorInfo.companyName?.label} />
                      {errors?.companyName&&<div className='text-red-600'>{errors?.companyName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="crNumber" className="text-lg">{contractorInfo.crNumber?.label}</label>
                      <input type="text" name="crNumber" id="crNumber" defaultValue={contractor?.crNumber} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={contractorInfo.crNumber?.label} />
                      {errors?.crNumber&&<div className='text-red-600'>{errors?.crNumber}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="companyPhone" className="text-lg">{contractorInfo.companyPhone?.label}</label>
                      <input type="text" name="companyPhone" id="companyPhone" defaultValue={contractor?.companyPhone} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={contractorInfo.companyPhone?.label} />
                      {errors?.companyPhone&&<div className='text-red-600'>{errors?.companyPhone}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="companyAddress" className="text-lg">{contractorInfo.companyAddress?.label}</label>
                      <input type="text" name="companyAddress" id="companyAddress" defaultValue={contractor?.companyAddress} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={contractorInfo.companyAddress?.label} />
                      {errors?.companyAddress&&<div className='text-red-600'>{errors?.companyAddress}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="personName" className="text-lg">{contractorInfo.personName?.label}</label>
                      <input type="text" name="personName" id="personName" defaultValue={contractor?.personName} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={contractorInfo.personName?.label} />
                      {errors?.personName&&<div className='text-red-600'>{errors?.personName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="personEmail" className="text-lg">{contractorInfo.personEmail?.label}</label>
                      <input type="text" name="personEmail" id="personEmail" defaultValue={contractor?.personEmail} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={contractorInfo.personEmail?.label} />
                      {errors?.personEmail&&<div className='text-red-600'>{errors?.personEmail}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="personPhone" className="text-lg">{contractorInfo.personPhone?.label}</label>
                      <input type="text" name="personPhone" id="personPhone" defaultValue={contractor?.personPhone} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={contractorInfo.personPhone?.label} />
                      {errors?.personPhone&&<div className='text-red-600'>{errors?.personPhone}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="crImgCopy" className="text-lg">{contractorInfo.crImgCopy?.label}</label>
                      <input type="file" name="crImgCopy" id="crImgCopy" className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={contractorInfo.crImgCopy?.label} />
                      {errors?.crImgCopy&&<div className='text-red-600'>{errors?.crImgCopy}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="service" className="text-lg">{contractorInfo.service?.label}</label>
                      <select name="service" id="service" defaultValue={contractor?.service} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={contractorInfo.service?.label}>
                      <option value={''}>{contractorInfo.service?.label}</option> 
                      {utils.servicesList.map(service=>(
                        <option key={service} value={service}>{service}</option> 
                      ))
                      }
                      </select>
                      {errors?.service&&<div className='text-red-600'>{errors?.service}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                  <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{route==="edit"?t("editBtn"):t("addBtn")}</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddOrEditContractors;