import React, { useEffect, useState } from 'react'
import useCheckRouteAuthorization from '../../apis/useCheckRouteAuthorization';
import * as utils from '../../utils/FormUtils/VisasUtils'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ValidationError } from 'yup';
import axios, { axiosPrivate } from '../../apis/axios';
import { useAuth } from '../../hooks/appHooks';
import useAxiosFetchApi from '../../hooks/useFetch';
import ContentDetails from '../ContentDetails';
import AppLoading from '../../utils/AppLoading';

const SCREEN_CONTENTS_URL = "/contents/route";
const VISA_SAVE_INFO_URL = "/visas/save"
const COUNTRIES_LIST_URL = "https://restcountries.com/v3.1/all";

const AddOrEditVisas = () => {
  useCheckRouteAuthorization();
  const { auth, setAuth } = useAuth();
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const visaInfo = t("visaInfo");
  const [contentState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/exh-visas"), {}, auth.token);
  const [countriesList, setCountriesList] = useState([]);
  const visaContents = contentState.data?.list;
  const visa = location.state?.visa;
  const route = location.pathname.split("/")[2];
  useEffect(()=>{
    if((!visa&&route==="edit")) navigate("/visas")
  }, [navigate, visa, route])

  useEffect(()=>{
    const fetchCoutries = async () => {
      const result = await axios.get(COUNTRIES_LIST_URL);
      console.log(result)
      setCountriesList(result.data);
    }
    fetchCoutries();
  }, [setCountriesList])

  const [errors, setErrors] = useState();
  
    const handleAddOrEditVisa = async(e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const formData = Object.fromEntries(data.entries());
        try {
        await utils.validationSchema(visaInfo, visa, countriesList, t("requiredMessage"))
        .validate(formData, {abortEarly: false});
        const requestBody = {
          ...formData,
          visaId: visa?visa?.visaId:null,
          exhibitorId: auth.exhibitor.exhibitorId
        }
        const visaData = new FormData();
        utils.fillExhibitorVisaData(visaData, formData.passportPhoto ,requestBody)
        const infoResponse = await axiosPrivate.post(VISA_SAVE_INFO_URL, visaData,
            {headers: {'Accept-Language': i18n.language}}
        );
        var visaList = auth.exhibitor.visas;
        if(!visa){ 
          e.target.querySelectorAll('input').forEach(input => {
            input.value = '';
          });
        } else {visaList = auth.exhibitor.visas.filter(vs=>vs.visaId!==visa?.visaId)}
        setAuth({...auth, exhibitor: {...auth.exhibitor, visas: [...visaList, infoResponse.data?.data]}})
        toast.success(infoResponse?.data.message);
      } catch (error) {
        if(error instanceof ValidationError){
          let allErrors = {};
          console.error("validation error...")
          error?.inner?.forEach((err)=>{
            allErrors[err.path]=err.message;
          })
          setErrors(allErrors)
        }
        toast.error(error.response?.data.message)
      }
    }

  return (
    <div>
      <ToastContainer/>
        <h2>{visa?visaInfo.editVisaTxt:visaInfo.addVisaTxt} {visa&&visa?.fullName}</h2>
        <div className='my-6'>
          {contentState.isLoading
          ? <AppLoading />
          : visaContents?.map(content=> <ContentDetails padding={"p-1 ps-3"} key={content.contentId} propContent={content}/>)}
        </div>
        <div className='flex justify-between items-center'>
            <form className='w-full' onSubmit={handleAddOrEditVisa} method='post'>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="fullName" className="text-lg">{visaInfo.fullName?.label}</label>
                      <input type="text" name="fullName" id="fullName" defaultValue={visa?.fullName} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.fullName?.label} />
                      {errors?.fullName&&<div className='text-red-600'>{errors?.fullName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="nationality" className="text-lg">{visaInfo.nationality?.label}</label>
                      <select name="nationality" id="nationality" value={visa?.nationality} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.nationality?.label}>
                        {!countriesList
                        ? "Loading..."
                        : countriesList
                        .sort((a, b) => {
                          if (a.name.common < b.name.common) return -1;
                          if (a.name.common > b.name.common) return 1;
                          return 0;
                        })
                        .map(country=><option value={`${country.name.common}`}>{country.name.common}</option>)
                        }
                      </select>
                      {errors?.nationality&&<div className='text-red-600'>{errors?.nationality}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="jobTitle" className="text-lg">{visaInfo.jobTitle?.label}</label>
                      <input type="text" name="jobTitle" id="jobTitle" defaultValue={visa?.jobTitle} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.jobTitle?.label} />
                      {errors?.jobTitle&&<div className='text-red-600'>{errors?.jobTitle}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="gender" className="text-lg">{visaInfo.gender?.label}</label>
                      <select name="gender" id="gender" defaultValue={visa?.gender} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.gender?.label}>
                        <option value="MALE">MALE</option>  
                        <option value="FEMALE">FEMALE</option>  
                      </select>
                      {errors?.gender&&<div className='text-red-600'>{errors?.gender}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="placeOfBirth" className="text-lg">{visaInfo.placeOfBirth?.label}</label>
                      <input type="text" name="placeOfBirth" id="placeOfBirth" defaultValue={visa?.placeOfBirth} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.placeOfBirth?.label} />
                      {errors?.placeOfBirth&&<div className='text-red-600'>{errors?.placeOfBirth}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="dateOfBirth" className="text-lg">{visaInfo.dateOfBirth?.label}</label>
                      <input type="date" name="dateOfBirth" id="dateOfBirth" defaultValue={visa?.dateOfBirth} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.dateOfBirth?.label} />
                      {errors?.dateOfBirth&&<div className='text-red-600'>{errors?.dateOfBirth}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="passportType" className="text-lg">{visaInfo.passportType?.label}</label>
                      <select name="passportType" id="passportType" defaultValue={visa?.passportType} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.passportType?.label}>
                        <option value="NORMAL">NORMAL</option>  
                        <option value="OTHER">OTHER</option>  
                      </select>
                      {errors?.passportType&&<div className='text-red-600'>{errors?.passportType}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="passportNumber" className="text-lg">{visaInfo.passportNumber?.label}</label>
                      <input type="text" name="passportNumber" id="passportNumber" defaultValue={visa?.passportNumber} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.passportNumber?.label} />
                      {errors?.passportNumber&&<div className='text-red-600'>{errors?.passportNumber}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="passportExpire" className="text-lg">{visaInfo.passportExpire?.label}</label>
                      <input type="date" name="passportExpire" id="passportExpire" defaultValue={visa?.passportExpire} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.passportExpire?.label} />
                      {errors?.passportExpire&&<div className='text-red-600'>{errors?.passportExpire}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="passportIssuePlace" className="text-lg">{visaInfo.passportIssuePlace?.label}</label>
                      <input type="text" name="passportIssuePlace" id="passportIssuePlace" defaultValue={visa?.passportIssuePlace} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.passportIssuePlace?.label} />
                      {errors?.passportIssuePlace&&<div className='text-red-600'>{errors?.passportIssuePlace}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="passportPhoto" className="text-lg">{visaInfo.passportPhoto?.label}</label>
                      <input type="file" name="passportPhoto" id="passportPhoto" className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.passportPhoto?.label} />
                      {errors?.passportPhoto&&<div className='text-red-600'>{errors?.passportPhoto}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="age" className="text-lg">{visaInfo.age?.label}</label>
                      <input type="number" name="age" id="age" defaultValue={visa?.age} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.age?.label} />
                      {errors?.age&&<div className='text-red-600'>{errors?.age}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="companyName" className="text-lg">{visaInfo.companyName?.label}</label>
                      <input type="text" name="companyName" id="companyName" defaultValue={visa?.companyName} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.companyName?.label} />
                      {errors?.companyName&&<div className='text-red-600'>{errors?.companyName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="companyActivity" className="text-lg">{visaInfo.companyActivity?.label}</label>
                      <input type="text" name="companyActivity" id="companyActivity" defaultValue={visa?.companyActivity} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.companyActivity?.label} />
                      {errors?.companyActivity&&<div className='text-red-600'>{errors?.companyActivity}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="religion" className="text-lg">{visaInfo.religion?.label}</label>
                      <input type="text" name="religion" id="religion" defaultValue={visa?.religion} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.religion?.label} />
                      {errors?.religion&&<div className='text-red-600'>{errors?.religion}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="travelSource" className="text-lg">{visaInfo.travelSource?.label}</label>
                      <select name="travelSource" id="travelSource" value={visa?.travelSource} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={visaInfo.travelSource?.label}>
                        {!countriesList
                        ? "Loading..."
                        : countriesList
                        .sort((a, b) => {
                          if (a.name.common < b.name.common) return -1;
                          if (a.name.common > b.name.common) return 1;
                          return 0;
                        })
                        .map(country=><option value={`${country.name.common}`}>{country.name.common}</option>)
                        }
                      </select>
                      {errors?.travelSource&&<div className='text-red-600'>{errors?.travelSource}</div>}
                    </div>
                    <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{route==="edit"?t("editBtn"):t("addBtn")}</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddOrEditVisas