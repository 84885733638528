import React from 'react'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import useAxiosFetchApi from '../hooks/useFetch';
import { useAuth } from '../hooks/appHooks';
import AppLoading from '../utils/AppLoading';
import { BASE_URL } from '../apis/axios';
const SCREEN_CONTENTS_URL = "/contents/route";

const FloorPlan = () => {
  const { auth } = useAuth();
  useCheckRouteAuthorization();
  const [contentState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/floor-plan"), {}, auth.token);
  const floorContents = contentState.data?.list;
  return (
    <>
    {contentState.isLoading || !floorContents
      ? <AppLoading />
      : <div className='flex-col my-10'>
        {floorContents?.length>=1 &&
        floorContents[0]?.img &&
        <img src={`${BASE_URL}/files/get/file/${floorContents[0]?.img}`} alt={floorContents[0]?.contentId} />
        }
      </div>}
    </>
  )
}

export default FloorPlan