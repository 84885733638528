import React, { useState } from 'react'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useAuth } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import useAxiosFetchApi from '../hooks/useFetch';
import AppLoading from '../utils/AppLoading';
import ContentDetails from './ContentDetails';
import { Link } from 'react-router-dom';
import { MdDelete, MdDownload, MdEdit } from 'react-icons/md';
import ConfirmationModal from './modals/ConfirmationModal';
import { BsCheck2Circle } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate } from '../apis/axios';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';
import templatePdf from '../img/badge_template.pdf';

const SCREEN_CONTENTS_URL = "/contents/route";
const BADGE_DELETE_URL = "badges/delete";
const Badges = () => {
  const { auth, setAuth } = useAuth();
  const {t, i18n} = useTranslation();
  useCheckRouteAuthorization();
  const badgeInfo = t("badgeInfo") 
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState({badgeId: null, isShown: false});
  const [contentState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/badges"), {}, auth.token);
  const badgeContents = contentState.data?.list;
  const allBadges = auth.exhibitor?.badges;

  const handleDeleteBadge = async ()=>{
    try {
      const badgeDeletedResponse = await axiosPrivate.delete(BADGE_DELETE_URL+`/${deleteModal.badgeId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(badgeDeletedResponse?.data.message);
      const afterDeleted = allBadges.filter(badge=> badge.badgeId !== deleteModal.badgeId);
      setAuth({...auth, exhibitor: {...auth.exhibitor, badges: afterDeleted}})
      setDeleteModal({badge: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const badges = allBadges?.filter((data) =>
    Object.values(data).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const generatePDF = async () => {
    const existingPdfBytes = await fetch(templatePdf).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const { width, height } = firstPage.getSize();

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;

    firstPage.drawText(`FIRST NAME: Ibrahim`, {
      x: 50,
      y: height - 150,
      size: fontSize,
      font,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(`LAST NAME: Ahmed`, {
      x: 50,
      y: height - 170,
      size: fontSize,
      font,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(`TITLE: Title`, {
      x: 50,
      y: height - 190,
      size: fontSize,
      font,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(`COMPANY: Restatex`, {
      x: 50,
      y: height - 210,
      size: fontSize,
      font,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(`EMAIL ADDRESS: ibrahim@gmail.com`, {
      x: 50,
      y: height - 230,
      size: fontSize,
      font,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(`MOBILE NUMBER: +966512345678`, {
      x: 50,
      y: height - 250,
      size: fontSize,
      font,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(`COUNTRY: Saudi Arabia`, {
      x: 50,
      y: height - 270,
      size: fontSize,
      font,
      color: rgb(0, 0, 0),
    });

    const pdfBytes = await pdfDoc.save();
    saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), 'badge.pdf');
  };

  return (
    <div>
      
      {contentState.isLoading
      ? <AppLoading />
      // : !badgeContents
      // ? <p>{contentState.data.message??contentState.error.message}</p>
      : badgeContents?.map(content=> <ContentDetails key={content.contentId} propContent={content}/>)}
      <div>
        <ToastContainer/>
        <div className="flex justify-between w-full">
          <div className='flex w-1/2'>
              <h2>{badgeInfo.badgesTitle}</h2>
              <input value={searchQuery}
                     onChange={(e) => setSearchQuery(e.target.value)}
                     className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={badgeInfo.searchBadgeTxt} />
          </div>
          <Link className='bg-primary-color text-white p-2 text-sm'
          to={"add"}>
              {badgeInfo.addBadgeTxt}
          </Link>
        </div>
        <div className="overflow-x-auto w-full scrollbar-custom">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.firstName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.lastName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-1 border-r">{badgeInfo.companyName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-1 border-r">{badgeInfo.jobTitle.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.city.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.phone.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.email.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.type.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("statusTxt")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                      {!allBadges? <tr><td colSpan={11} className='p-10'>
                          <AppLoading/>
                          </td></tr>
                      : badges.length===0?<tr><td colSpan={11} className='p-10'>{badgeInfo.noBadges}</td></tr>
                      : badges.map((badge)=>{
                        const badgeApproved = badge?.status==="APPROVED";
                      return <tr key={badge.badgeId} className='border-b'>
                      <td className="whitespace-nowrap border-r p-4">{badge.firstName} </td>
                      <td className="whitespace-nowrap border-r p-4">{badge.lastName} </td>
                      <td className="whitespace-nowrap border-r p-4">{badge.companyName}</td>
                      <td className="whitespace-nowrap border-r p-4">{badge.jobTitle?badge.jobTitle:"_"}</td>
                      <td className="whitespace-nowrap border-r p-4">{badge.city?badge.city:"_"}</td>
                      <td className="whitespace-nowrap border-r p-4">{badge.phone?badge.phone:"_"}</td>
                      <td className="whitespace-nowrap border-r p-4">{badge.email?badge.email:"_"}</td>
                      <td className="whitespace-nowrap border-r p-4">{badge.type?badge.type:"_"}</td>
                      <td className="whitespace-nowrap border-r p-4">
                        {<p className='lowercase'>{badge.status}</p>}
                      </td>
                      <td className="whitespace-nowrap border-r py-4 flex justify-center">
                        { badgeApproved?<><BsCheck2Circle color='green'/>
                        <button className='bg-green-300 mx-0.5' onClick={generatePDF}>
                            <MdDownload size={17}/>
                        </button></>
                        : <>
                            <Link className='bg-green-300 mx-0.5' to={"edit"} state={{badge}}>
                                <MdEdit size={17}/>
                            </Link>
                            <button className='bg-red-300 mx-0.5' onClick={()=> 
                              setDeleteModal({badgeId: badge.badgeId, isShown: true})}>
                                <MdDelete size={17}/>
                            </button>
                          </>}
                      </td>
                      <ConfirmationModal
                        key={badge?.badgeId}
                        title={badgeInfo.confirmDeleteTitle}
                        btnColor={"bg-danger"}
                        message={badgeInfo.confirmDeleteBody}
                        onAction={handleDeleteBadge}
                        showModal={deleteModal.isShown}
                        setShowModal={(value)=>setDeleteModal({badgeId: null, isShown: value})} />
                      </tr>
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Badges