import *as Yup from 'yup'

export const validationSchema =(agreementTexts, formData, requiredMessage)=> {return Yup.object({
    signatory: Yup.string().required(requiredMessage),
    cosigner: Yup.string().required(requiredMessage),
    title: Yup.string().notRequired(),
    notes: Yup.string().notRequired(),
    cost: Yup.string().when([], {
      is: () => formData.cost,
      then: ()=> Yup.number().typeError(agreementTexts.cost.onlyNumber).required(requiredMessage),
      otherwise: ()=> Yup.string().notRequired()
    }),
    signatoryLogo: Yup.mixed().when([], {
    is: () => formData.signatoryLogo.name!=='',
    then: ()=> Yup.mixed()
      .test(
        'fileSize',
        'حجم الصورة غير مدعوم',
        value => value && value.size <= 1024 * 1024 * 25 // 25 MB
      )
      .test(
        'fileType',
        `الملفات المدعومة png, jpg, jpeg, pdf فقط`,
        value => value && ["image/png", "image/jpg", "image/jpeg"].includes(value.type)
      ),
      otherwise: ()=> Yup.mixed().notRequired(), // No validation when editing
    }),
    cosignerLogo: Yup.mixed().when([], {
    is: () => formData.cosignerLogo.name!=='',
    then: ()=> Yup.mixed()
      .test(
        'fileSize',
        'حجم الصورة غير مدعوم',
        value => value && value.size <= 1024 * 1024 * 25 // 25 MB
      )
      .test(
        'fileType',
        `الملفات المدعومة png, jpg, jpeg, pdf فقط`,
        value => value && ["image/png", "image/jpg", "image/jpeg"].includes(value.type)
      ),
      otherwise: ()=> Yup.mixed().notRequired(), // No validation when editing
    }),
})}

export const fillExhibitorAgreementData=(agreementData, signatoryLogo, cosignerLogo, requestBody)=>{
    agreementData.append("AgreementPayload", JSON.stringify({
        agreementId: requestBody.agreementId, exhibitorId: requestBody.exhibitorId,
        signatory: requestBody.signatory,
        cosigner: requestBody.cosigner,
        title: requestBody.title,
        notes: requestBody.notes,
        cost: requestBody.cost!=='' ? requestBody.cost : null
      }));
      agreementData.append("signatoryLogo", signatoryLogo);
      agreementData.append("cosignerLogo", cosignerLogo);
}