import React, { useState } from 'react'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useAuth } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from './modals/ConfirmationModal';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import AppLoading from '../utils/AppLoading';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate, BASE_URL } from '../apis/axios';
const AGREEMENT_DELETE_URL = "/agreements/delete";

const Agreements = () => {
  const { auth, setAuth } = useAuth();
  const {t, i18n} = useTranslation();
  useCheckRouteAuthorization();
  const agreementInfo = t("agreementInfo")
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState({agreementId: null, isShown: false});
  // const [contentState] = useAxiosFetchApi(SCREEN_CONTENTS_URL.concat("/agreements"), {}, auth.token);
  // const agreementContents = contentState.data?.list;
  const allAgreements = auth.exhibitor?.agreements;

  const handleDeleteAgreement = async ()=>{
    try {
      const agreementDeletedResponse = await axiosPrivate.delete(AGREEMENT_DELETE_URL+`/${deleteModal.agreementId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(agreementDeletedResponse?.data.message);
      const afterDeleted = allAgreements.filter(agreement=> agreement.agreementId !== deleteModal.agreementId);
      setAuth({...auth, exhibitor: {...auth.exhibitor, agreements: afterDeleted}})
      setDeleteModal({agreement: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  
  const agreements = allAgreements?.filter((data) =>
    Object.values(data).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div>
      <div>
        <ToastContainer/>
        <div className="flex justify-between w-full mt-12">
          <div className='flex w-1/2'>
              <h2>{agreementInfo.agreementsTitle}</h2>
              <input value={searchQuery}
                     onChange={(e) => setSearchQuery(e.target.value)}
                     className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={agreementInfo.searchAgreementTxt} />
          </div>
          <Link className='bg-primary-color text-white p-2 text-sm'
          to={"add"}>
              {agreementInfo.addAgreementTxt}
          </Link>
        </div>
        <div className="overflow-x-auto w-full mb-12 scrollbar-custom">
          <div className="inline-block min-w-full my-10">
              <div className="overflow-hidden">
                  <table className="min-w-full border text-center text-sm">
                  <thead
                      className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                      <tr key={"head-1"}>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{agreementInfo.title.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{agreementInfo.signatory.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-1 border-r">{agreementInfo.cosigner.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-1 border-r">{agreementInfo.notes.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{agreementInfo.cost.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                      </tr>
                  </thead>
                  <tbody className='border-b'>
                    {!allAgreements? <tr><td colSpan={11} className='p-10'>
                        <AppLoading/>
                        </td></tr>
                    : agreements.length===0?<tr><td colSpan={11} className='p-10'>{agreementInfo.noAgreements}</td></tr>
                    : agreements.map((agreement)=>{
                    return <tr key={agreement.agreementId} className='border-b'>
                    <td className="whitespace-nowrap border-r p-4">{agreement.title??'_'} </td>
                    <td className="whitespace-nowrap border-r p-4">
                        <div className='flex items-center justify-around'>
                            {agreement.signatoryLogo
                            ? <img width={"40%"} src={`${BASE_URL}/files/get/file/${agreement.signatoryLogo}`} alt={agreement.signatory} />
                            : ""
                          }<p className='text-lg'>{agreement.signatory}</p>
                        </div>
                    </td>
                    <td className="whitespace-nowrap border-r p-4">
                        <div className='flex items-center justify-around'>
                          {agreement.cosignerLogo
                            ? <img width={"40%"} src={`${BASE_URL}/files/get/file/${agreement.cosignerLogo}`} alt={agreement.cosigner} />
                            : ""
                          }<p className='text-lg'>{agreement.cosigner}</p>
                        </div>
                    </td>
                    <td className="border-r p-4">{agreement.notes??'_'}</td>
                    <td className="whitespace-nowrap border-r p-4">{agreement.cost??'_'}</td>
                    <td className="whitespace-nowrap border-r py-4 flex justify-center">
                      {<>
                          <Link className='bg-green-300 mx-0.5' to={"edit"} state={{agreement}}>
                              <MdEdit size={17}/>
                          </Link>
                          <button className='bg-red-300 mx-0.5' onClick={()=> 
                            setDeleteModal({agreementId: agreement.agreementId, isShown: true})}>
                              <MdDelete size={17}/>
                          </button>
                        </>}
                    </td>
                    <ConfirmationModal
                      key={agreement?.agreementId}
                      title={agreementInfo.confirmDeleteTitle}
                      btnColor={"bg-danger"}
                      message={agreementInfo.confirmDeleteBody}
                      onAction={handleDeleteAgreement}
                      showModal={deleteModal.isShown}
                      setShowModal={(value)=>setDeleteModal({agreementId: null, isShown: value})} />
                    </tr>
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Agreements