import React, { useRef } from 'react';
import {useAuth} from '../hooks/appHooks';
import logo from '../img/resta-logo.png';
import { useNavigate } from 'react-router-dom';
import {FaSignInAlt} from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify';
import LanguageSelector from "../utils/LanguageSelector"
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from '../apis/axios';
import loginImage from "../img/login_bg.jpeg"
const LOGIN_URL = "/auth/login";

const Login = () => {
    const {setAuth} = useAuth()
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const navigate = useNavigate()
    const {t, i18n} = useTranslation();
    const tLogin = t("login")
    const from = "/home"
    
    const handleAnonimousLogin = async (e)=>{
        e.preventDefault();
        try {
            const email = emailRef.current.value;
            const password = passwordRef.current.value;
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({email, password}),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(JSON.stringify(response?.data));

            localStorage.setItem("ecms_token", response?.data.token)
            localStorage.setItem("refresh_token", response?.data.refreshToken)
            setAuth(response?.data);
            navigate(from, { replace: true })
            toast.success('Login successfully');
        } catch (error) {
            toast.error(error.response?.data.message);
        }
    }
    return (
    <div className='cairo-font'>
        <ToastContainer/>
        <div className='flex justify-start items-center ps-10 h-screen login_bg'>
            <div className='flex flex-col justify-start w-full md:w-4/12 pe-10'>
                <LanguageSelector width='100%'/>
                <div className='flex justify-between items-center'>
                    <h2 className='uppercase'>
                        {tLogin.title}
                        {i18n.language==="en" && <sub className='m-2 bg-white font-extrabold p-2'>
                            {tLogin.loginTxt} <span><FaSignInAlt style={{display: 'inline'}} /></span>
                        </sub>}
                    </h2>
                    <img src={logo} alt="logo" className='my-3 w-[30%] bg-primary-color rounded-xl' width={150}/>
                </div>
                <h3 className="text-lg md:text-xl my-11 dark:text-white">
                    {tLogin.subtitle}
                </h3>
                <form className="space-y-4 md:space-y-12 py-2.5" onSubmit={handleAnonimousLogin} method='post'>
                    <div>
                    <label htmlFor="email" className="mb-2 text-lg ">{tLogin.usernameLabel}</label>
                    <input ref={emailRef} type="text" name="email" id="email" className="sm:text-sm w-full p-2.5 shadow-lg" placeholder="name@company.com" required />
                    </div>
                    <div>
                        <label htmlFor="password" className="mb-2 text-lg ">{tLogin.passwordLabel}</label>
                        <input ref={passwordRef} type="password" name="password" id="password" placeholder="••••••••" className="sm:text-sm w-full p-2.5 shadow-lg" required />
                    </div>
                    <button type="submit" className="w-full text-white bg-primary-color px-5 py-2.5 shadow-lg">{tLogin.loginTxt}</button>
                </form>
            </div>
            <div className='hidden md:flex h-screen w-full bg-primary-color'>
                <img className='w-full' src={loginImage} alt="Logo" />
            </div>
        </div>
    </div>
    )
}

export default Login