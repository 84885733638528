import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()

  const goHome = () => navigate('/home')

  return (
    <div className='flex flex-col space-y-5 justify-center items-center h-[100vh]'>
       <img 
        src="https://via.placeholder.com/400x300?text=404+Not+Found" 
        alt="404 Not Found" 
        className='w-96 h-auto'
      />
      <h1 className='text-red-700 font-bold px-2 text-2xl'>404 Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <button onClick={goHome} className='px-4 py-2'>
        Back to Homepage
      </button>
    </div>
  )
}

export default NotFound