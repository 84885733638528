import React, { useEffect, useState } from 'react'
import useCheckRouteAuthorization from '../../apis/useCheckRouteAuthorization';
import * as utils from '../../utils/FormUtils/BadgesUtils'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { axiosPrivate } from '../../apis/axios';
import { useAuth } from '../../hooks/appHooks';

const BADGE_SAVE_INFO_URL = "/badges/save"

const AddOrEditBadges = () => {
  useCheckRouteAuthorization();
  const { auth, setAuth } = useAuth();
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const badgeInfo = t("badgeInfo");
  const badge = location.state?.badge;
  const route = location.pathname.split("/")[2];
  useEffect(()=>{
    if((!badge&&route==="edit")) navigate("/badges")
  }, [navigate, badge, route])

  const [errors, setErrors] = useState();
  
    const handleAddOrEditBadge = async(e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const formData = Object.fromEntries(data.entries());
        try {
        await utils.validationSchema(badgeInfo, formData, t("requiredMessage"))
        .validate(formData, {abortEarly: false});
        const requestBody = {
          ...formData,
          badgeId: badge?badge?.badgeId:null,
          exhibitorId: auth.exhibitor.exhibitorId
        }
        const infoResponse = await axiosPrivate.post(BADGE_SAVE_INFO_URL, requestBody,
            {headers: {'Accept-Language': i18n.language}}
        );
        navigate(-1);
        var badgeList = auth.exhibitor.badges;
        if(!badge){ 
          e.target.querySelectorAll('input').forEach(input => {
            input.value = '';
          });
        } else {badgeList = auth.exhibitor.badges.filter(bd=>bd.badgeId!==badge?.badgeId)}
        setAuth({...auth, exhibitor: {...auth.exhibitor, badges: [...badgeList, infoResponse.data?.data]}})
        toast.success(infoResponse?.data.message);
      } catch (error) {
        if(error instanceof ValidationError){
          let allErrors = {};
          console.error("validation error...")
          error?.inner?.forEach((err)=>{
            allErrors[err.path]=err.message;
          })
          setErrors(allErrors)
        }
        toast.error(error.response?.data.message)
      }
    }

  return (
    <div>
      <ToastContainer/>
        <h2>{badge?badgeInfo.editBadgeTxt:badgeInfo.addBadgeTxt} {badge&&badge?.firstName}</h2>
        <div className='flex justify-between items-center'>
            <form className='w-full' onSubmit={handleAddOrEditBadge} method='post'>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="firstName" className="text-lg">{badgeInfo.firstName?.label}</label>
                      <input type="text" name="firstName" id="firstName" defaultValue={badge?.firstName} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={badgeInfo.firstName?.label} />
                      {errors?.firstName&&<div className='text-red-600'>{errors?.firstName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="lastName" className="text-lg">{badgeInfo.lastName?.label}</label>
                      <input type="text" name="lastName" id="lastName" defaultValue={badge?.lastName} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={badgeInfo.lastName?.label} />
                      {errors?.lastName&&<div className='text-red-600'>{errors?.lastName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="jobTitle" className="text-lg">{badgeInfo.jobTitle?.label}</label>
                      <input type="text" name="jobTitle" id="jobTitle" defaultValue={badge?.jobTitle} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={badgeInfo.jobTitle?.label} />
                      {errors?.jobTitle&&<div className='text-red-600'>{errors?.jobTitle}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="companyName" className="text-lg">{badgeInfo.companyName?.label}</label>
                      <input type="text" name="companyName" id="companyName" defaultValue={badge?.companyName} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={badgeInfo.companyName?.label} />
                      {errors?.companyName&&<div className='text-red-600'>{errors?.companyName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="city" className="text-lg">{badgeInfo.city?.label}</label>
                      <input type="text" name="city" id="city" defaultValue={badge?.city} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={badgeInfo.city?.label} />
                      {errors?.city&&<div className='text-red-600'>{errors?.city}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="type" className="text-lg">{badgeInfo.type?.label}</label>
                      <select name="type" id="type" defaultValue={badge?.type} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={badgeInfo.type?.label}>
                        <option value="VIP">VIP Allowed #15</option>  
                        <option value="MEDIA">Media</option>  
                        <option value="SPONSOR">Sponsor</option>  
                      </select>
                      {errors?.type&&<div className='text-red-600'>{errors?.type}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="phone" className="text-lg">{badgeInfo.phone?.label}</label>
                      <input type="text" name="phone" id="phone" defaultValue={badge?.phone} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={badgeInfo.phone?.label} />
                      {errors?.phone&&<div className='text-red-600'>{errors?.phone}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="email" className="text-lg">{badgeInfo.email?.label}</label>
                      <input type="text" name="email" id="email" defaultValue={badge?.email} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={badgeInfo.email?.label} />
                      {errors?.email&&<div className='text-red-600'>{errors?.email}</div>}
                    </div>
                    <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{route==="edit"?t("editBtn"):t("addBtn")}</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddOrEditBadges